<template>
  <content-wrapper title="Minutes">
    <file-list :file-types="fileTypes" :allows-archives="true" :is-logged-in="$parent.loggedIn"></file-list>
  </content-wrapper>
</template>

<script>
import Files from "@/components/Files";
import ContentWrapper from "@/components/ContentWrapper";
import FileList from "@/components/FileList.vue";

export default {
  name: "Minutes",
  components: {FileList, Files, ContentWrapper},
  methods: {},
  data() {
    return {
      fileTypes: ['Minutes', 'AnnualBoard', 'Other', 'Committee'],
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
