<template>
  <div class="container">
    <h2 v-if="title">{{ title }}</h2>
    <file-edit-form v-if="editing"
                    :file="file"
                    :available-file-types="availableFileTypes"
                    :allows-archives="allowsArchives"
                    @clear-file="clearFile"
                    @done-edit="doneEditing"/>
    <div v-if="loggedIn">
      <div class="buttons">
        <b-button v-if="allowNew" icon-left="upload" data-test="files-add-button" @click="newFile" type="is-black">Add
          File
        </b-button>
      </div>
    </div>

    <div v-if="this.files.length > 0" data-test="files-list">
      <ul>
        <li v-for="file in files">
          <file-item :editable="true" @edit="editFile(file)" :file="file" :logged-in="loggedIn"></file-item>
        </li>
      </ul>
    </div>
    <div v-else-if="loading" class="has-margin-top">
      Loading...
    </div>
    <div v-else-if="!editing" class="has-margin-top empty" data-test="files-none">
      No files yet.
    </div>
  </div>
</template>

<script>
import Http from "@/services/Http";
import FileItem from "@/components/FileItem.vue";
import FileEditForm from "@/components/FileEditForm.vue";
import EditFile from "@/models/EditFile";

export default {
  name: "Files",
  components: {
    FileEditForm,
    FileItem
  },
  props: {
    title: {type: String},
    loggedIn: {type: Boolean},
    showArchive: {type: Boolean, default: false},
    fileType: {type: String, default: ""},
    fileTypes: {type: Array},
    showType: {type: Boolean, default: false},
    allowNew: {type: Boolean, default: true},
    allowsArchives: {type: Boolean, required: true}
  },
  data() {
    return {
      files: [],
      file: new EditFile({}),
      loading: true,
      editing: false
    }
  },
  mounted() {
    this.loadFiles();
  },
  computed: {
    availableFileTypes() {
      return this.fileTypes ? this.fileTypes : [this.fileType];
    }
  },
  methods: {
    getType(model) {
      return model.$error ? "is-danger" : "";
    },
    loadFiles() {
      const params = {
        "showArchive": this.showArchive,
        "types": this.availableFileTypes.concat(',')
      }

      Http.get("/api/files", params)
        .then(response => {
          response.data.forEach(next => {
            next.date = new Date(Date.parse(next.date));
          });
          this.files = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newFile() {
      const newFile = new EditFile({
        type: (this.fileType !== "") ? this.fileType : this.availableFileTypes[0],
        archive: this.showArchive
      });
      this.editFile(newFile);
    },
    editFile(file) {
      this.file = new EditFile(file);
      this.editing = true;
    },
    clearFile() {
      this.editing = false;
    },
    doneEditing() {
      this.clearFile();
      this.loadFiles();
    },
  }
}
</script>

<style lang="scss" scoped>

.has-margin-top {
  margin-top: 24px;
}

.empty {
  margin-bottom: 0.75rem;
}

</style>
