<template>
  <content-wrapper title="Search">
    <div class="search">
      <form class="field is-grouped" @submit.prevent="handleSearch(searchQuery)">
        <div class="control is-expanded">
          <b-input type="text" v-model="searchQuery"
                   icon="search-web"
                   icon-right="close-circle" :icon-right-clickable="true" @icon-right-click="clearSearch"
                   :loading="loading"/>
        </div>
        <div class="control">
          <b-button type="is-black" native-type="submit">Search</b-button>
        </div>
      </form>
    </div>
    <p>
      <div class="results" v-if="hasResults">
        <div class="tabs is-boxed">
          <ul>
            <li :class="{ 'is-active': activeTab === 'files' }" @click="activeTab = 'files'">
              <a>Files ({{ fileResults.totalResults }})</a>
            </li>
            <li :class="{ 'is-active': activeTab === 'images' }" @click="activeTab = 'images'">
              <a>Images ({{ imageResults.totalResults }})</a>
            </li>
          </ul>
        </div>
        <div v-if="activeTab === 'files'">
          <file-search-results :results="fileResults" :logged-in="loggedIn"></file-search-results>
        </div>
        <div v-if="activeTab === 'images'">
          <image-search-results :results="imageResults" :logged-in="loggedIn"></image-search-results>
        </div>
      </div>
      <div v-else-if="searchError">
        <p class="has-text-danger">{{ searchError }}</p>
      </div>
      <div v-else>
        <p>Start typing to search for files.</p>
      </div>
      <div class="loading" v-if="loading">
        <b-loading :is-full-page="true" :active.sync="loading"/>
      </div>
    </p>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper";
import Files from "@/components/Files";
import FileItem from "@/components/FileItem.vue";
import debounce from "buefy/src/utils/debounce";
import FileSearchResults from "@/pages/search/FileSearchResults.vue";
import ImageSearchResults from "@/pages/search/ImageSearchResults.vue";
import SearchResult from "@/models/SearchResult";

export default {
  name: "Search",
  components: {
    ImageSearchResults,
    FileSearchResults,
    ContentWrapper,
    Files,
    FileItem
  },
  methods: {
    handleSearch: debounce(function (query) {
      if (!query) {
        this.clearSearch();
        return;
      }
      this.loading = true;
      this.searchError = "";
      this.axios.get("/api/search", {params: {query}})
        .then(response => {
          this.fileResults = new SearchResult(
            response.data.query,
            response.data.files,
            response.data.fileTotal
          );
          this.imageResults = new SearchResult(
            response.data.query,
            response.data.images,
            response.data.imageTotal
          );
          this.hasResults = true;
        })
        .catch(error => {
          this.searchError = "An error occurred during the search.";
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),
    clearSearch() {
      this.searchQuery = "";
      this.searchError = "";
      this.fileResults = new SearchResult("", [], 0);
      this.imageResults = new SearchResult("", [], 0);
      this.hasResults = false;
    }
  },
  data() {
    return {
      loading: false,
      hasItems: false,
      hasResults: false,
      searchError: "",
      searchQuery: "",
      fileResults: new SearchResult("", [], 0),
      imageResults: new SearchResult("", [], 0),
      activeTab: 'files' // Default active tab
    }
  },
  computed: {
    loggedIn() {
      return this.$parent.loggedIn;
    }
  },
  mounted() {
  },
  watch: {
    searchQuery(newQuery) {
      this.handleSearch(newQuery);
    }
  }
}
</script>

<style lang="scss" scoped>

.tabs {
  ul {
    margin-left: 0;
  }
}

</style>
