<template>
  <b-modal @close="clearFile()" :active="true" scroll="keep">
    <div class="box form">
      <h3>Add File</h3>
      <b-field grouped group-multiline>
        <b-field label="Name"
                 :type="getType($v.file.name)"
                 :message="getMessage($v.file.name)"
                 expanded>
          <b-input data-test="file-name-input" v-model="$v.file.name.$model"></b-input>
        </b-field>
        <b-field label="Date" expanded>
          <b-datepicker data-test="date-input" v-model="file.date" placeholder="Click to select..."></b-datepicker>
        </b-field>
      </b-field>
      <b-field>
        <b-field label="Description" :message="aiNote" expanded>
          <b-input type="textarea" data-test="description-input" v-model="file.description"></b-input>
        </b-field>
      </b-field>
      <b-field>
        <b-field label="Summary" :message="aiNote" expanded>
          <b-input type="textarea" data-test="summary-input" v-model="file.summary"></b-input>
        </b-field>
      </b-field>
      <b-field label="Type">
        <b-select v-model="file.type" data-test="type-input" :disabled="isSingleFileType">
          <option v-for="option in availableFileTypes" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Status" v-if="allowsArchives">
        <b-checkbox v-model="file.archive">Archive</b-checkbox>
      </b-field>
      <b-field label="Upload"
               :type="getType($v.file.name)"
               :message="getMessage($v.file.name)">
        <b-upload v-model="uploadFile" data-test="file-input">
          <b-button tag="a" type="is-black" icon-left="upload">Click to Upload</b-button>
        </b-upload>
      </b-field>
      <b-field label="Generative AI Actions" v-if="file.id" grouped group-multiline>
        <b-field>
          <b-button type="is-info" icon-left="auto-fix" data-test="files-describe-button" @click="generateDescription"
                    :loading="loading">
            Describe
          </b-button>
        </b-field>
        <b-field>
          <b-button type="is-info" icon-left="auto-fix" data-test="files-summary-button" @click="generateSummary"
                    :loading="loading">
            Summarize
          </b-button>
        </b-field>
      </b-field>
      <b-field label="File Actions" grouped group-multiline>
        <b-field>
          <b-button type="is-success" icon-left="content-save" data-test="files-save-button" @click="saveFile"
                    :loading="loading">Save
          </b-button>
        </b-field>
        <b-field>
          <b-button icon-left="cancel" data-test="files-cancel-button" @click="clearFile">Cancel</b-button>
        </b-field>
        <b-field v-if="file.id">
          <b-button icon-left="delete" data-test="files-delete-button" @click="deleteFile" type="is-danger"
                    :loading="loading">Delete
          </b-button>
        </b-field>
      </b-field>
    </div>
  </b-modal>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators';
import EditFile from "@/models/EditFile";

export default {
  name: "FileEditForm",
  props: {
    file: {type: EditFile, required: true},
    availableFileTypes: {type: Array, required: true},
    allowsArchives: {type: Boolean, required: true}
  },
  validations: {
    file: {
      name: {required},
      dataFilename: {requiredIf: requiredIf((file) => file.id === null)}
    }
  },
  watch: {
    uploadFile: function () {
      this.updateFile();
    }
  },
  data() {
    return {
      loading: false,
      uploadFile: {}
    }
  },
  computed: {
    active() {
      return !!this.file;
    },
    aiNote() {
      return !this.file.id ? "You can generate this field with AI after the initial save" : null;
    },
    isSingleFileType() {
      return this.availableFileTypes.length === 1;
    }
  },
  methods: {
    updateFile() {
      this.file.setFile(this.uploadFile).finally(() => {
        this.$v.$touch();
      });
    },
    getType(model) {
      return model.$error ? "is-danger" : "";
    },
    getMessage(model) {
      if (model.$error && model.required === false) {
        return "This field is required";
      } else if (model.$error && model.email === false) {
        return "Email invalid";
      } else if (model.$error) {
        return "Value invalid";
      }
    },
    clearFile() {
      this.$emit('clear-file');
    },
    doneEdit() {
      this.$emit('done-edit');
    },
    deleteFile() {
      if (!this.loading) {
        const result = window.confirm(`Are you sure you want to delete ${this.file.name}?`);
        if (result) {
          this.loading = true;
          this.axios.delete(`/api/files/${this.file.id}`)
            .then(response => {
              this.doneEdit();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    saveFile() {
      this.$v.$reset();
      this.$v.$touch();
      if (!this.loading && !this.$v.$anyError) {
        this.loading = true;
        this.axios.post("/api/files", this.file)
          .then(response => {
            this.doneEdit();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    generateSummary() {
      this.loading = true;
      const failureMessage = "Unable to generate summary";
      this.axios.post(`/api/files/${this.file.id}/summary`)
        .then(response => {
          this.file.summary = response.data === "" ? failureMessage : response.data;
        })
        .catch(error => {
          console.log(error);
          this.file.summary = failureMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateDescription() {
      this.loading = true;
      const failureMessage = "Unable to generate description";
      this.axios.post(`/api/files/${this.file.id}/description`)
        .then(response => {
          this.file.description = response.data === "" ? failureMessage : response.data;
        })
        .catch(error => {
          console.log(error);
          this.file.description = failureMessage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>

<style lang="scss" scoped>

.form {
  padding-bottom: 2rem;

  .field.is-grouped-multiline {
    .field {
      margin-bottom: 0.75rem;
    }
  }
}

</style>
