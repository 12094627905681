import { render, staticRenderFns } from "./Minutes.vue?vue&type=template&id=bbcc4018&scoped=true"
import script from "./Minutes.vue?vue&type=script&lang=js"
export * from "./Minutes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbcc4018",
  null
  
)

export default component.exports