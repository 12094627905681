<template>
  <content-wrapper title="Private Files">
    <file-list :file-types="fileTypes" :allows-archives="true" :is-logged-in="$parent.loggedIn"></file-list>
  </content-wrapper>
</template>

<script>
import Files from "@/components/Files";
import {required} from "vuelidate/lib/validators";
import ContentWrapper from "@/components/ContentWrapper";
import FileList from "@/components/FileList.vue";

export default {
  name: "Minutes",
  components: {FileList, Files, ContentWrapper},
  methods: {},
  data() {
    return {
      fileTypes: ['Private'],
    }
  },
  mounted() {
    this.$parent.onLogin(isLoggedIn => {
      if (!isLoggedIn) this.$router.replace({path: '/'});
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
