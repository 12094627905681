// frontend/src/models/EditFile.js

class EditFile {
  constructor({ id, name, date, description, summary, type, archive, dataContentType, dataFilename, data, fileKey }) {
    this.id = id || null;
    this.name = name || '';
    this.date = date || new Date();
    this.description = description || '';
    this.summary = summary || '';
    this.type = type || '';
    this.archive = archive || false;
    this.dataContentType = dataContentType || '';
    this.dataFilename = dataFilename || '';
    this.data = data || '';
    this.fileKey = fileKey || '';
  }

  toBase64(uploadFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(uploadFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async setFile(uploadFile) {
    this.dataContentType = uploadFile.type;
    this.dataFilename = uploadFile.name;
    this.data = await this.toBase64(uploadFile);
  }
}

export default EditFile;


