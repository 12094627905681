<template>
  <content-wrapper title="News">
    <file-list :file-types="fileTypes" :allows-archives="true" :is-logged-in="$parent.loggedIn"></file-list>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper";
import Files from "@/components/Files";
import FileList from "@/components/FileList.vue";

export default {
  name: "News",
  components: {FileList, ContentWrapper, Files},
  methods: {},
  data() {
    return {
      fileTypes: ['News','Newsletter'],
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
