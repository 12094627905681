<template>
  <div class="block">
    <div>
      <a :href="file.url" target="_blank">{{ file.name }}</a>&nbsp;
      <strong>({{ formattedType }})</strong>
      <span v-if="file.description">&nbsp;{{ file.description }}</span>&nbsp;
      <span v-if="editable && loggedIn" @click="editFile" class="button icon is-primary">
        <i class="mdi mdi-square-edit-outline"/>
      </span>
    </div>
    <a-i-summary v-show="file.summary && loggedIn" :text="file.summary"/>
  </div>
</template>

<script>
import AISummary from "@/components/AiSummary.vue";

export default {
  name: "FileItem",
  components: {
    AISummary
  },
  props: {
    file: {
      type: Object,
      required: true
    },
    loggedIn: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedType() {
      return this.file.type
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\bOr\b/g, 'or');
    }
  },
  methods: {
    editFile() {
      this.$emit('edit', this.file);
    }
  }
}
</script>

<style lang="scss" scoped>

.button {
  cursor: pointer;
}

.ai-summary {
  margin-top: 10px;
  white-space: pre-wrap;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
}

.ai-notice {
  font-size: 0.8em;
  color: gray;
  margin-top: 8px;
  margin-bottom: 10px;
}

</style>
