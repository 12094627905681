```vue
<template>
  <div>
    <div class="ai-summary">{{ text }}</div>
    <div class="ai-notice">
      <em>Note: This AI-generated summary is used for search (except private files) but not visible to the public.</em>
    </div>
  </div>
</template>

<script>
export default {
  name: "AISummary",
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.ai-summary {
  margin-top: 10px;
  white-space: pre-wrap;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
}

.ai-notice {
  font-size: 0.8em;
  color: gray;
  margin-top: 8px;
  margin-bottom: 10px;
}

</style>
