<template>
  <div>
    <h2>Files</h2>
    <p>Showing results for: <strong>{{ results.query }}</strong> (showing {{ results.currentTotal() }} of {{ results.totalResults }} items) </p>
    <div v-if="results.hasResults()">
      <ul>
        <li v-for="file in results.results" :key="file.id">
          <file-item :file="file" :logged-in="loggedIn"></file-item>
        </li>
      </ul>
    </div>
    <div v-else>
      No results found.
    </div>
  </div>
</template>

<script>
import FileItem from "@/components/FileItem.vue";
import SearchResult from "@/models/SearchResult";

export default {
  name: "FileSearchResults",
  components: {
    FileItem
  },
  props: {
    results: {
      type: SearchResult,
      required: true
    },
    loggedIn: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
