<template>
  <content-wrapper title="General Info">
    <div class="container">
      <file-list title="Legal Document" :file-types="['LegalDocument']"
                 :allows-archives="false" :is-logged-in="$parent.loggedIn"></file-list>
    </div>

    <div class="container">
      <file-list title="Map or Directory" :file-types="['MapOrDirectory']"
                 :allows-archives="false" :is-logged-in="$parent.loggedIn"></file-list>
    </div>

    <div class="container">
      <file-list title="Land Record" :file-types="['LandRecord']"
                 :allows-archives="false" :is-logged-in="$parent.loggedIn"></file-list>
    </div>

    <div class="container">
      <file-list title="Miscellaneous" :file-types="['Miscellaneous']"
                 :allows-archives="false" :is-logged-in="$parent.loggedIn"></file-list>
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper";
import Links from "@/components/Links";
import Files from "@/components/Files";
import FileList from "@/components/FileList.vue";

export default {
  name: "Info",
  components: {FileList, ContentWrapper, Links, Files},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
