<template>
  <div>
    <h2>Images</h2>
    <p>Showing results for: <strong>{{ results.query }}</strong> (showing {{ results.currentTotal() }} of
      {{ results.totalResults }} items) </p>
    <div class="image-list small-images" v-if="results.hasResults()">
      <div v-for="image in results.results" :key="image.name" class="tile is-parent">
        <div>
          <div class="image" :aria-label="image.description" :style="{background: `url('${image.url}')`}"/>
          <a-i-summary v-if="image.description && loggedIn" :text="image.description"/>
        </div>
      </div>
    </div>
    <div v-else>
      No results found.
    </div>
  </div>
</template>

<script>
import SearchResult from "@/models/SearchResult";
import AISummary from "@/components/AiSummary.vue";

export default {
  name: "ImageSearchResults",
  components: {AISummary},
  props: {
    results: {
      type: SearchResult,
      required: true
    },
    loggedIn: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.small-images {
  margin-bottom: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    margin-bottom: 5px;
    margin-right: 5px;

    div.image {
      width: 300px;
      height: 300px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      float: left;
      border-radius: 5px;
    }

    div {
      float: left;
      width: 300px;
    }
  }

}

/* Add media query for mobile devices */
@media (max-width: 768px) {
  .small-images {
    margin: auto;

    div.tile {
      div {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

</style>
