<template>
  <div>
    <div class="container">
      <files :title="title" :allows-archives="allowsArchives" :file-types="fileTypes" :logged-in="isLoggedIn"></files>
      <div v-if="allowsArchives">
        <h2>Archive</h2>
        <b-button data-test="show-archive-button"
                  icon-left="eye-outline"
                  @click="showArchive = true"
                  v-if="!showArchive">
          Show Archive
        </b-button>
        <b-collapse :open.sync="showArchive">
          <files :allow-new="false" :allows-archives="allowsArchives" :show-archive="true" :file-types="fileTypes"
                 :logged-in="isLoggedIn"></files>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Files from "@/components/Files";

export default {
  name: "FileList",
  components: {
    Files
  },
  props: {
    fileTypes: {type: Array, required: true},
    allowsArchives: {type: Boolean, required: true},
    isLoggedIn: {type: Boolean, default: false},
    title: {type: String, default: null}
  },
  data() {
    return {
      showArchive: false
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.container {
  margin-bottom: 24px;
}

</style>
