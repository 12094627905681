// frontend/src/models/ResultWrapper.js
export default class SearchResult {
  constructor(query, results, totalResults) {
    this.query = query;
    this.results = results;
    this.totalResults = totalResults;
  }

  currentTotal() {
    return this.results.length;
  }

  hasResults() {
    return this.totalResults > 0;
  }
}
