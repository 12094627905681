<template>
  <content-wrapper title="Contact">
    <h2>Board Members</h2>

    <div v-for="contact in contacts" :key="contact.role" class="contact">
      <div><strong>{{ contact.role }}</strong> {{ contact.name }}</div>
      <div><a :href="`mailto:${contact.email}`">{{ contact.email }}</a></div>
    </div>

    <p>For questions, concerns or closing letters contact a board member directly</p>

    <h3>Stonebrook Homeowners Discussion Group</h3>
    <p><a
      href="mailto:stonebrook-estates-homeowners@googlegroups.com">stonebrook-estates-homeowners@googlegroups.com</a>
    </p>
    <p>To add yourself to this list go to: <a href="https://groups.google.com/g/stonebrookestatescommunity">Stonebrook
      Estate Community</a></p>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper";

export default {
  name: "Contact",
  components: {
    ContentWrapper
  },
  data() {
    return {
      contacts: [
        {role: "President", name: "Nate Riesen", email: "president@stonebrookhoa.org"},
        {role: "Treasurer", name: "Michael Polley", email: "treasurer@stonebrookhoa.org"},
        {role: "Secretary", name: "Dave Suehs", email: "secretary@stonebrookhoa.org"},
        {role: "Conservancy Coordinator", name: "Jeannie Bettesworth", email: ""},
        {role: "Committee Members", name: "", email: "committeemembers@stonebrookhoa.org"},
        {role: "Architectural", name: "", email: "architectural@stonebrookhoa.org"},
        {role: "Outlot", name: "", email: "outlot@stonebrookhoa.org"},
        {role: "Social", name: "", email: "social@stonebrookhoa.org"}
      ]
    };
  }
};
</script>

<style lang="scss" scoped>

.contact {
  margin-bottom: 10px;
}

</style>
